
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class UuidField extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  public uuid: string;
  @Prop({
    type: String,
    required: false,
  })
  public label: string;

  public handleCopyUuid(): void {
    // copy uuid to clipboard
    navigator.clipboard.writeText(this.uuid);
  }
}
