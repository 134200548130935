
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ColorField extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  public value: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  public isContrastOk: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  public wcag: boolean;

  @Prop({
    type: String,
    required: true,
  })
  public label: string;

  @Prop({
    type: String,
    default: 'widgets.configform.wcagInfo',
  })
  public wcagInfoKey: string;

  public get color() {
    return this.value;
  }

  public set color(val) {
    this.$emit('input', val);
  }

  public get isFormatOk() {
    return new RegExp('^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$').test(this.color);
  }
}
