
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ValidationNotification extends Vue {
  @Prop({
    type: Array,
    required: false,
  })
  public messages: string[];

  @Prop({
    type: String,
    default: 'error',
  })
  public type: string;
}
