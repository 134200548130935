
import { Component, Prop, Vue } from 'vue-property-decorator';
import LogoUploadModal from '@/components/shared/LogoUploadModal.vue';
import { Inject } from 'inversify-props';
import { LOGO_SERVICE, LogoService } from '@/services';
@Component({
  components: { LogoUploadModal },
})
export default class ImageUpload extends Vue {
  public umbrellaSources = [];

  @Prop({ default: () => Date.now().toString() })
  public readonly modalId: string;

  @Prop({ type: Boolean })
  public readonly modNoPreview: boolean;

  @Prop({ type: Boolean })
  public readonly modIconPreview: boolean;

  @Prop()
  public value: string;

  @Prop()
  public label: string;

  @Prop()
  public directory: string;

  @Inject(LOGO_SERVICE)
  public logoService: LogoService;

  mounted() {
    this.fetchFilenames();
  }

  public fetchFilenames(): void {
    this.logoService
      .fetchFilenames(this.directory)
      .then(data => {
        this.umbrellaSources = data;
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  public setSelectedSource(value: string): void {
    this.$emit('input', value);
  }

  public fileUploaded(value: string): void {
    this.fetchFilenames();
    this.setSelectedSource(value);
  }
}
