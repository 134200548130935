
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { LOGO_SERVICE, LogoService } from '@/services';

@Component
export default class LogoUploadModal extends Vue {
  public selectedFileName = '';

  public formData = null;
  public uploadInProgress = false;

  @Prop()
  public id: string;

  @Prop()
  public directory: string;

  @Inject(LOGO_SERVICE)
  public logoService: LogoService;

  public addedSuccess(fileData): void {
    this.formData = new FormData();
    this.formData.append('file', fileData, fileData.name);
    this.selectedFileName = fileData.name;
  }

  public uploadImage(): void {
    this.uploadInProgress = true;

    this.logoService
      .upload(this.formData, this.directory)
      .then(data => {
        this.uploadInProgress = false;
        this.setSelectedSource(data.response);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  public setSelectedSource(value: string): void {
    this.$emit('file-uploaded', value);
    this.closeModal();
  }

  public closeModal() {
    this.selectedFileName = '';
    this.formData = null;
    this.$root.$emit('modal-toggle', this.id);
  }
}
